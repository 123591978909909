import { axiosInstance } from '../../../../../services/axios-instance';

export const MayLikeServices = {
  getMayLike: async (param: string) => {
    const [town, county] = param.split('/');
    return await axiosInstance
      .get('/advertisement/specialOffers/8', {
        params: {
          city: town,
          state: county,
          plansReference: 'ofertasDestaqueHome,ofertasEspeciaisHome,perfeitoIlimitado',
        },
      })
      .then(response => response)
      .catch(error => error.response);
  },
};

export const MayLikeParts = {
  getMayLikeParts: async () => {
    return await axiosInstance
      .get('/advertisement/parts/bestOffers/ad_page/4')
      .then(response => response)
      .catch(error => error.response);
  },
};

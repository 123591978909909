import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import {
  alignItems,
  display,
  flexDirection,
  flexWrap,
  justifyContent,
  marginTop,
} from 'styled-system';
import { CardSpecialOffers } from '../CardSpecialOffers';
import { Container } from '../Container';
import { Grid } from '../Grid';
import { GridCell } from '../GridCell';
import { LoaderCard } from '../Loaders';
import { WrapperSectionHome } from '../WrapperSectionHome';
import { Ad } from './Ad';
import { spotlight } from './services/';
import styles from './style.module.css';
import { treatAdvertsements, treatStore } from './treatData';

interface Props {
  locale?: any;
}

export const ListCards = inject(
  'locale',
  'user',
  'selectedStore',
)(
  observer(({ locale }: Props) => {
    const { city } = locale;
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [advertisements, setAdvertisements] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [store, setStore] = useState({
      subtitle: '',
      urlCompanyLogo: '',
      storeUrl: '',
    });

    useEffect(() => {
      const fetchData = async () => {
        const response = await spotlight.getSpotlight(city);
        if (response) {
          setStore(treatStore(response));
          setAdvertisements(treatAdvertsements(response));
        }
        setLoading(false);
      };
      fetchData()
        .then(r => r)
        .catch(err => console.error(err.error));
    }, [city]);

    const storeClass = cx('flex flex-column justify-center mr6 mr12-m mr12-l', styles.storeClass);

    const wrapper = cx('overflow-hidden', styles.wrapperCards);

    const { subtitle } = store;

    const mapCards = () =>
      map(advertisements, advertisement => (
        <Ad key={advertisement.id} data={advertisement} clientName={subtitle} />
      ));

    const mapCardsMobile = () =>
      map(advertisements.slice(0, -1), advertisement => (
        <Ad key={advertisement.id} data={advertisement} clientName={subtitle} />
      ));

    const renderContent = () => {
      const { storeUrl, urlCompanyLogo } = store;
      const dataStore = {
        title: 'Ofertas especiais',
        storeName: subtitle,
        logoUrl: urlCompanyLogo,
      };

      return loading ? (
        <LoaderCard />
      ) : (
        <WrapperSectionHome
          linkTo={storeUrl}
          textLinkTo="Ir para página da loja"
          linkClass="pl16 pl0-ns pt24"
          linkAria="Ir para página da loja"
          home
          dataValue={dataStore.storeName}
        >
          <h2 className={`${styles.text} abbey`}>
            Ofertas <span>especiais</span>
          </h2>
          <Grid width={1} className={wrapper}>
            <GridCell className={storeClass}>
              <CardSpecialOffers data={dataStore} storeUrl={storeUrl} />
            </GridCell>
            {mapCards()}
          </Grid>
        </WrapperSectionHome>
      );
    };

    const renderMobileContent = () => {
      const { storeUrl, urlCompanyLogo } = store;
      const dataStore = {
        title: 'Ofertas especiais',
        storeName: subtitle,
        logoUrl: urlCompanyLogo,
      };
      return loading ? (
        <LoaderCard />
      ) : (
        <WrapperSectionHome
          linkTo={storeUrl}
          textLinkTo="Ir para página da loja"
          linkClass="pl16 pl0-ns pt24"
          linkAria="Ir para página da loja"
          home
          dataValue={dataStore.storeName}
        >
          <h2 className={`${styles.text} abbey`}>
            Ofertas <span>especiais</span>
          </h2>
          <Grid width={1} className={`${wrapper} flex-column`} style={{ alignItems: 'center' }}>
            <GridCell className={`${storeClass} flex-row justify-center`}>
              <CardSpecialOffers data={dataStore} storeUrl={storeUrl} />
            </GridCell>
            <GridCell
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'nowrap',
                marginTop: '16px',
                justifyContent: 'center',
              }}
            >
              {mapCardsMobile()}
            </GridCell>
          </Grid>
        </WrapperSectionHome>
      );
    };

    return (
      <>
        {/* <div className="mb48 mb0-l mb0-m db dn-ns">{renderContent()}</div> */}
        <>
          <div className="mb48 mb0-l mb0-m dn db-ns">
            <Container>{renderContent()}</Container>
          </div>
          {isMobile && (
            <div className="mb48 db">
              <Container>{renderMobileContent()}</Container>
            </div>
          )}
        </>
      </>
    );
  }),
);

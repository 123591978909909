import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { map } from 'lodash';
import { inject, observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { Grid } from '../../../../components/Grid';
import { LoaderCard } from '../../../../components/Loaders';
import { StoreCard } from '../../../../components/StoreCard';
import { WrapperSectionHome } from '../../../../components/WrapperSectionHome';
import styles from './SectionFive.module.css';
import { TopStores } from './services/topStoresService';
import { formattedTopStore } from './utils/formatterTopStores';

interface Props {
  locale?: any;
}
export const SectionFive = inject('locale')(
  observer(({ locale }: Props) => {
    const [data, setData] = useState<any>();
    const [showLoader, setShowLoader] = useState(true);
    const { city } = locale;
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const s3 = process.env.FTP_IMAGENS;

    useEffect(() => {
      const fetchData = async () => {
        const response = await TopStores.getTopStores(city)
          .then(res => res)
          .catch(err => err);
        if (response) {
          setData(formattedTopStore(response.data, s3));
          setShowLoader(false);
        }
      };
      fetchData();
    }, [city]);

    const renderStoreCard = () => {
      const storeCard = cx('mb12 mb0-ns ph0-ns', styles.storeCard);
      if (showLoader) {
        return <LoaderCard />;
      }
      return map(data, (item, idx) => {
        const { id, imageData, coverPhoto, bestOffers, storeName, storeLocation } = item;
        return (
          <div key={`${storeName}-${idx}`} className={storeCard}>
            <StoreCard
              logo={imageData}
              cover={coverPhoto}
              offers={bestOffers}
              name={storeName}
              location={storeLocation}
              id={id}
              target={isMobile ? '_self' : '_blank'}
            />
          </div>
        );
      });
    };

    if (data && !data.length) {
      return null;
    }
    const gridClass = cx('flex justify-between-ns', styles.grid);
    return (
      <div className="mt48 mt72-ns mt48-m">
        <WrapperSectionHome
          initialText="Confira as"
          bolderText="lojas perto de você"
          textLinkTo="Ver todas as lojas"
          linkTo="/lojas/"
          linkAria="Ir para página de lojas"
          home
        >
          <Grid width={1} className={gridClass}>
            {renderStoreCard()}
          </Grid>
        </WrapperSectionHome>
      </div>
    );
  }),
);

import React from 'react';
import { CardAd } from '../../../../../../components/CardAd';
import { urlFormatted } from '../../../../utils/formattedUrl';

interface DataType {
  km: number;
  id: number;
  name: string;
  price: number;
  motor: number;
  carName: string;
  version: string;
  modelYear: number;
  brandName: string;
  urlPictures: string[];
  fabricationYear: number;
  city: string;
  state: string;
  type: any;
  user: { id: number };
}
interface Props {
  data: DataType;
  clientName: string;
}
export const BannerAds = ({ data, clientName }: Props) => {
  const {
    km,
    id,
    name,
    price,
    carName,
    modelYear,
    brandName,
    version,
    urlPictures,
    fabricationYear,
    state,
    city,
    type,
    user,
  } = data;
  const urlFomatted = urlFormatted({
    id,
    city,
    state,
    model: { name, brandName, version, type },
    user: { id: user?.id },
  });
  return (
    <CardAd
      km={km}
      price={price}
      carName={carName}
      modelYear={modelYear}
      clientName={clientName}
      urlPictures={urlPictures}
      fabricationYear={fabricationYear}
      url={urlFomatted}
    />
  );
};

import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import { CardAd } from '../../../../components/CardAd';
import { Grid } from '../../../../components/Grid';
import { GridCell } from '../../../../components/GridCell';
import { LoaderCard } from '../../../../components/Loaders';
import { WrapperSectionHome } from '../../../../components/WrapperSectionHome';
import { PARTS_NUMBER } from '../../../../utils/productType';
import { urlFormatted } from '../../utils/formattedUrl';
import { TopOffersServicesByCity, TopOffersParts } from './services/TopOffersServices';

interface Props {
  locale?: any;
}
export const SectionTwo = inject('locale')(
  observer(({ locale }: Props) => {
    const [response, setResponse] = useState<any>();
    const [partsResponse, setPartsResponse] = useState<any>();
    const [loading, setLoading] = useState(true);
    const { city } = locale;

    useEffect(() => {
      const fetchData = async () => {
        await TopOffersServicesByCity.getTopOffers(city)
          .then(res => {
            if (res.status === 200) {
              setResponse(res.data);
            }
          })
          .catch(err => err);
        setLoading(false);
      };

      const fetchPartsData = async () => {
        await TopOffersParts.getTopPartsOffers()
          .then(res => {
            if (res.status === 200) {
              setPartsResponse(res.data);
            }
          })
          .catch(err => err);
        setLoading(false);
      };

      fetchData();
      fetchPartsData();
    }, [city]);

    const renderTopOffersPartsCards = () => {
      return loading ? (
        <LoaderCard />
      ) : (
        map(partsResponse, (offer, idx) => {
          const { user, name, price, photoUrl, newPart } = offer;
          offer.vehicleType = PARTS_NUMBER;
          const newUrl = urlFormatted(offer);
          const userNameAd = get(user, user.type === 1 ? 'name' : 'fantasyName', '') || '';

          return (
            <GridCell
              key={`${name}-${idx}`}
              className="mb12"
              width={[0.4792, 0.23713, 0.2313, 0.23594]}
            >
              <CardAd
                carName={`${name}`}
                clientName={userNameAd}
                price={price}
                urlPictures={photoUrl}
                url={newUrl}
                vehicleType={PARTS_NUMBER}
                isNew={newPart}
              />
            </GridCell>
          );
        })
      );
    };

    const renderTopOffersCards = () => {
      return loading ? (
        <LoaderCard />
      ) : (
        map(response, (offer, idx) => {
          const offerModel = get(offer, 'model', {});
          const { user, mileage, price, photoUrl } = offer;
          const { brandName, name, modelYear, fabricationYear, version } = offerModel;
          const newUrl = urlFormatted(offer);
          const userNameAd = get(user, user.type === 1 ? 'name' : 'fantasyName', '') || '';

          return (
            <GridCell
              key={`${name}-${idx}`}
              className="mb12"
              width={[0.4792, 0.23713, 0.2313, 0.23594]}
            >
              <CardAd
                carName={`${brandName} ${name} ${version}`}
                modelYear={modelYear}
                fabricationYear={fabricationYear}
                km={mileage}
                clientName={userNameAd}
                price={price}
                urlPictures={photoUrl}
                url={newUrl}
              />
            </GridCell>
          );
        })
      );
    };

    const checkPartsResponse = () => {
      if (partsResponse && !partsResponse.length) {
        return null;
      }

      return (
        <div className="pt32 pt48-ns pt0-m pt0-l mt32">
          <WrapperSectionHome
            initialText="Top "
            bolderText="ofertas "
            restText="da semana"
            linkTo="/anuncios/busca/?categoria=pecas"
            textLinkTo="Ver todas as ofertas"
            linkAria="Ir para página de ofertas"
            home
          >
            <Grid width={1} className="pb12 pb0-ns justify-between">
              {renderTopOffersPartsCards()}
            </Grid>
          </WrapperSectionHome>
        </div>
      );
    };

    const checkResponse = () => {
      if (response && !response.length) {
        return null;
      }

      return (
        <div className="pt32 pt48-ns pt0-m pt0-l">
          <WrapperSectionHome
            initialText="Top "
            bolderText="ofertas "
            restText="da semana"
            linkTo="/anuncios/busca"
            textLinkTo="Ver todas as ofertas"
            linkAria="Ir para página de ofertas"
            home
          >
            <Grid width={1} className="pb12 pb0-ns justify-between">
              {renderTopOffersCards()}
            </Grid>
          </WrapperSectionHome>
        </div>
      );
    };

    return (
      <>
        {checkResponse()}
        {checkPartsResponse()}
      </>
    );
  }),
);

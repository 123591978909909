import React, { useState, useEffect } from 'react';
import { Container } from '../../components/Container';
import { ListCards } from '../../components/ListCards';
import { Visibility } from '../../components/Visibility';
import { VideoCard } from '../../components/YoutubeCard/VideoCard';
import { SectionBanner } from './components/SectionBanner';
import { SectionFive } from './components/SectionFive';
import { SectionOne } from './components/SectionOne';
import { SectionSeven } from './components/SectionSeven';
import { SectionSix } from './components/SectionSix';
import { SectionThree } from './components/SectionThree';
import { SectionTwo } from './components/SectionTwo';

const Home = () => {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth <= 768);
      const updateScreenSize = () => setIsMobile(window.innerWidth <= 768);

      window.addEventListener('resize', updateScreenSize);

      return () => window.removeEventListener('resize', updateScreenSize);
    }
  }, []);

  // useEffect(() => {
  //   const updateScreenSize = () => setIsMobile(window.innerWidth <= 768);

  //   window.addEventListener('resize', updateScreenSize);

  //   return () => window.removeEventListener('resize', updateScreenSize);
  // }, []);

  return (
    <>
      <SectionOne />
      <div id="mainHome" className="w-100 bg-wild-sand pt48">
        <ListCards />
        <Container>
          <Visibility>
            <SectionBanner position="pageInitialOne" />
          </Visibility>
          <SectionTwo />

          {isMobile && <SectionSeven />}

          {/* <SectionThree /> */}
          <SectionFive />
          <SectionSix />
          <Visibility>
            <SectionBanner position="pageInitialTwo" />
          </Visibility>

          {!isMobile && <SectionSeven />}
          <VideoCard />
        </Container>
      </div>
    </>
  );
};

export { Home };

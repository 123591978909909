import React, { useEffect, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { map } from 'lodash';
import { inject, observer } from 'mobx-react';
import { justifyContent } from 'styled-system';
import { Grid } from '../../../../components/Grid';
import { GridCell } from '../../../../components/GridCell';
import { spotlight } from '../../../../components/ListCards/services';
import { treatSagaAdvertsements, treatStore } from '../../../../components/ListCards/treatData';
import { MainBanner } from './components/BoxBanners';
import { BannerAds } from './components/BoxBanners/BannerAds';
import { mainBannerFormatted } from './components/utils/adapterBanners';

interface Props {
  data: any;
  locale?: any;
}

export const RenderSectionSeven: React.FunctionComponent<Props> = inject(
  'locale',
  'selectedStore',
)(
  observer(({ data, locale }) => {
    const mainBanner = mainBannerFormatted(data, locale.city.split('/')[0]);
    const [loading, setLoading] = useState(true);
    const [advertisements, setAdvertisements] = useState<any>();
    const [store, setStore] = useState({ subtitle: '' });

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await spotlight.getSagaAutominasCars();
          if (response) {
            setStore(treatStore(response));
            setAdvertisements(treatSagaAdvertsements(response));
          }
        } catch (err) {
          console.error(err.error);
        }
        setLoading(false);
      };
      fetchData();
    }, []);

    const { subtitle } = store;

    const mapBannerCards = () =>
      map(advertisements, advertisement => (
        <GridCell
          width={[1, 6 / 12]}
          className="pl0 pl12-ns pl24-m pl24-l mb12"
          style={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'center' }}
        >
          <BannerAds data={advertisement} clientName={subtitle} />
        </GridCell>
      ));

    return (
      <div className="mt40 mt0-m mt0-l pb72-ns vw-100">
        <Grid className="mt40 mt0-m mt0-l flex justify-center">
          <GridCell width={[1, 7 / 12]} className="br10">
            <MainBanner data={mainBanner} />
          </GridCell>
          <GridCell
            width={[1, 5 / 12]}
            className="pl0 pl12-ns pl24-m pl24-l"
            style={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'center' }}
          >
            <Grid className="mt40 mt0-m mt0-l flex justify-center">{mapBannerCards()}</Grid>
          </GridCell>
        </Grid>
      </div>
    );
  }),
);

export const SectionSeven = () => {
  const banners = graphql`
    query {
      allDirectusBanner {
        nodes {
          city {
            city_name
          }
          position
          link
          large_image {
            data {
              full_url
            }
          }
        }
      }
    }
  `;
  const renderSectionSevenBanners = (data: any) => {
    const {
      allDirectusBanner: { nodes },
    } = data;
    return <RenderSectionSeven data={nodes} />;
  };

  return <StaticQuery query={banners} render={renderSectionSevenBanners} />;
};

import { axiosInstanceWithAuth } from '../../../services/axios-instance';

export const spotlight = {
  getSpotlight: async (param: string) => {
    const [town, county] = param.split('/');

    return await axiosInstanceWithAuth
      .get('/user/specialStores/1', {
        params: { city: town, state: county, plansReference: 'ofertasEspeciaisHome' },
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => res)
      .catch(err => console.error(err));
  },
  getSagaAutominasCars: async () => {
    return await axiosInstanceWithAuth
      .get('advertisement/anunciosHome', {
        params: { page: 0, take: 2, userId: 23 },
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => res)
      .catch(err => console.error(err));
  },
};
